$(document).on('turbolinks:load', function () {
  if ($('.wizard-provider-form').length > 0) {
    const changeTextDni = () => {

      const replaceText = (inputElement, labelElement, message, labelText) => {
        if (message || labelText) {
          inputElement.attr("placeholder", "Ingresa tu" + " " + labelText.toUpperCase());
          labelElement.text(message.split("(")[0]);
        }
      }

      const labelElement = $("label.col-form-label[for='provider_business_id']");
      const inputElement = $("#provider_business_id");
      const providerBusinessIdNode = $('#provider_business_id_type')
      const defaultValueSelected = providerBusinessIdNode.val();

      providerBusinessIdNode.select2();
      const defaultTextSelected = $('#provider_business_id_type').find(':selected').text();

      replaceText(inputElement, labelElement, defaultTextSelected, defaultValueSelected);

      $("#provider_business_id_type").on('select2:select', function (e) {
        const valueSelected = $("#provider_business_id_type").val();
        const selectedItemText = e.params.data.text;
        replaceText(inputElement, labelElement, selectedItemText, valueSelected);
      });

    }

    const perCountryFields = () => {
      const updateDniTypes = (countryId) => {
        const dniTypesByCountry = {
          '142': [
            { value: 'curp', text: 'Clave Única de Registro de Población (CURP)' },
            { value: 'rfc', text: 'Registro Federal de Contribuyentes (RFC)' },
            { value: 'cp', text: 'Cédula Profesional (CP)' }
          ],
          '65': [
            { value: 'ruc', text: 'Registro Único de Contribuyente (RUC)' }
          ],
          '50': [
            { value: 'nit', text: 'Número de Identificación Fiscal (NIT)' },
            { value: 'cc', text: 'Cédula de Ciudadanía (CC)' },
            { value: 'ce', text: 'Cédula de Extranjería (CE)' },
            { value: 'pe', text: 'Permiso especial de Permanencia (PEP)' },
            { value: 'ppt', text: 'Permiso por Protección Temporal (PPT)' },
            { value: 'nuip', text: 'Número Único de Identificación Personal (NUIP)' },
          ],
          'default': [
            { value: 'rut', text: 'Rol único tributario (RUT)' }
          ]
        };

        const selectedDniTypes = dniTypesByCountry[countryId] || dniTypesByCountry['default'];
        const providerBusinessIdNode = $('#provider_business_id_type');

        providerBusinessIdNode.empty();
        selectedDniTypes.forEach((type) => {
          providerBusinessIdNode.append(new Option(type.text, type.value));
        });

        // Seleccionar automáticamente la primera opción
        providerBusinessIdNode.val(selectedDniTypes[0].value).trigger('change');

        // Llamar a changeTextDni para actualizar el texto relacionado
        const selectedText = selectedDniTypes[0].text;
        const selectedValue = selectedDniTypes[0].value;
        const inputElement = $("#provider_business_id");
        const labelElement = $("label.col-form-label[for='provider_business_id']");
        inputElement.attr("placeholder", "Ingresa tu " + selectedValue.toUpperCase());
        labelElement.text(selectedText.split("(")[0]);
      };

      const toggleFieldsAndValidateForm = () => {
        const selectedCountry = $('#provider_address_country_id').val();

        // Actualizar los tipos de DNI según el país seleccionado
        updateDniTypes(selectedCountry);

        // Código Postal - México
        if (selectedCountry == 142) {
          $('#postal_code_field').show();
          $('#provider_postal_code').prop('required', true).prop('disabled', false);
        } else {
          $('#postal_code_field').hide();
          $('#provider_postal_code').prop('required', false).prop('disabled', true).val('');
        }

        // Actividad Comercial - Colombia
        if (selectedCountry == 50) {
          $('#commercial_activity_field').show();
          $('#provider_commercial_activity').prop('required', true).prop('disabled', false);
          $('#is_self_retainer_field').show();
          $('#billing-rut-field').show();
        } else {
          $('#commercial_activity_field').hide();
          $('#provider_commercial_activity').prop('required', false).prop('disabled', true).val('');
          $('#is_self_retainer_field').hide();
          $('#billing-rut-field').hide();
        }

        validateForm();
      };

      const validateForm = () => {
        let isValid = true;
        $('.wizard-provider-form--form :input[required]:visible').each(function () {
          if (!$(this).val()) {
            isValid = false;
            return false;
          }
        });

        $('.wizard-provider-form--submit').prop('disabled', !isValid);
      };

      $('#provider_address_country_id').on('change', toggleFieldsAndValidateForm);
      toggleFieldsAndValidateForm();
      $('.wizard-provider-form--form').on('change', ':input[required]', validateForm);
    }

    perCountryFields()
    changeTextDni()
  }
});