function highlightBillingSection() {
  if (window.location.hash === "#branch-billing-section") {

    // Overlay oscuro
    const overlay = document.createElement("div");
    overlay.classList.add("highlight-billing-overlay");
    document.body.appendChild(overlay);

    // Resaltado del formulario de facturación
    const formContent = document.querySelector("#branch-billing-section");
    if (formContent) {
      formContent.classList.add("highlight-billing-section");
      formContent.scrollIntoView({ behavior: "smooth" });
    }

    // Mensaje flotante
    const tooltip = document.createElement("div");
    tooltip.classList.add("highlight-billing-tooltip");
    tooltip.textContent = `
      Aquí puedes agregar los datos de facturación.
      Cuando termines, haz clic en "Guardar sucursal".
    `;
    formContent.appendChild(tooltip);

    // Botón "Entendido"
    const button = document.createElement("button");
    button.classList.add("highlight-billing-tooltip-button");
    button.textContent = "Entendido";
    tooltip.appendChild(button);

    // El botón "Entendido" elimina todo
    button.addEventListener("click", () => {
      overlay.remove();
      tooltip.remove();
      formContent.classList.remove("highlight-billing-section");
    });
  }
}

document.addEventListener("turbolinks:load", highlightBillingSection);
window.addEventListener("hashchange", highlightBillingSection);
