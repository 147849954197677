import $ from "jquery";
import startAutoSendToggles from "../../utilities/auto_send_toggles";
import JustValidate from 'just-validate';
import globalConfigFormValidator from "../../utilities/formValidatorData";
import { allFields, chileanFields, colombianFields, defaultFields, mexicanFields } from "./financierBillingFormFields";

const dniTypesByCountry = {
  '142': [
    { value: 'curp', text: 'Clave Única de Registro de Población (CURP)' },
    { value: 'rfc', text: 'Registro Federal de Contribuyentes (RFC)' },
    { value: 'cp', text: 'Cédula Profesional (CP)' }
  ],
  '65': [
    { value: 'ruc', text: 'Registro Único de Contribuyente (RUC)' }
  ],
  '50': [
    { value: 'nit', text: 'Número de Identificación Fiscal (NIT)' },
    { value: 'cc', text: 'Cédula de Ciudadanía (CC)' },
    { value: 'ce', text: 'Cédula de Extranjería (CE)' },
    { value: 'pe', text: 'Permiso especial de Permanencia (PEP)' },
    { value: 'ppt', text: 'Permiso por Protección Temporal (PPT)' },
    { value: 'nuip', text: 'Número Único de Identificación Personal (NUIP)' },
  ],
  'default': [
    { value: 'rut', text: 'Rol único tributario (RUT)' }
  ]
};

const updateDniTypes = (selectedCountryId) => {
  const billingDniTypeSelect = $('#billing_business_id_type');
  const selectedDniTypes = dniTypesByCountry[selectedCountryId] || dniTypesByCountry['default'];

  // Obtener el valor guardado antes de limpiar el selector
  const savedValue = billingDniTypeSelect.data('saved-value') || billingDniTypeSelect.val();

  // Limpiar opciones existentes y agregar nuevas
  billingDniTypeSelect.empty();
  selectedDniTypes.forEach((type) => {
    billingDniTypeSelect.append(new Option(type.text, type.value));
  });

  // Establecer el valor inicial si coincide con las opciones disponibles
  if (savedValue && selectedDniTypes.some(type => type.value === savedValue)) {
    billingDniTypeSelect.val(savedValue).trigger('change');
  } else {
    billingDniTypeSelect.val(selectedDniTypes[0]?.value).trigger('change');
  }
};

const hideCountryFields = (countries) => {
  countries.forEach((country) => {
    const countryFields = $(`#${country}_fields`)
    countryFields.hide()
  })
}

const showCountryFields = (countries = []) => {
  countries.forEach((country) => {
    const countryFields = $(`#${country}_fields`)

    countryFields.show()
  })
}

const countryConfig = {
  '142': { // Mexico
    show: ["mexico"],
    hide: ["chilean", "colombia"]
  },
  '50': { // ID para Colombia
    show: ["colombia"],
    hide: ["chilean", "mexico"]
  },
  '1': { // ID para Chile
    show: ["chilean"],
    hide: ["colombia", "mexico"]
  }
}

let initialCountryId; // Variable global para guardar el país inicial

// Función para inicializar el país original
const initializeOriginalCountry = () => {
  initialCountryId = $('#financier_country_select').val(); // Almacena el valor inicial del selector
};

const toggleCountryFields = () => {
  const selectedCountryId = $('#financier_country_select').val(); // País actualmente seleccionado
  const alertElement = $('#country-change-alert'); // Elemento de la alerta

  // Mostrar alerta si el país seleccionado es diferente al original
  if (selectedCountryId !== initialCountryId) {
    alertElement.css('display', 'block');
  } else {
    alertElement.css('display', 'none');
  }

  updateDniTypes(selectedCountryId);

  addOrRemoveFieldsFormValidator(selectedCountryId);
  setCountryToBilling(selectedCountryId);

  const otherFields = Object.keys(countryFields).reduce((acc, key) => {
    if (key !== selectedCountryId.toString()) {
      acc[key] = countryFields[key];
    }
    return acc;
  }, {});

  removeFieldsFromBillingForm(otherFields)

  const config = countryConfig[selectedCountryId]

  if (config) {
    showCountryFields(config.show);
    hideCountryFields(config.hide);
  } else {
    hideCountryFields(["colombia", "chilean", "mexico"]);
  }

};

const removeFieldsFromBillingForm = (otherFields) => {
  Object.values(otherFields).forEach((field) => {
    Object.keys(field).forEach((fieldName) => {
      $(fieldName).val("");
    })
  })
}


const startToggleSections = () => {
  // Ocultar secciones inicialmente
  const insuranceSection = $('.financier-insurance-section');
  const creditSection = $('.financier-credit-section');

  const disableFields = (section) => {
    section.find('input, select, textarea').prop('disabled', true);
  };

  const enableFields = (section) => {
    section.find('input, select, textarea').prop('disabled', false);
  };

  // Inicialmente deshabilitar ambas secciones
  disableFields(insuranceSection);
  disableFields(creditSection);

  const toggleSections = (selectedType) => {
    if (selectedType === 'insurance') {
      insuranceSection.show();
      enableFields(insuranceSection);
      creditSection.hide();
      disableFields(creditSection);
    } else if (selectedType === 'credit') {
      creditSection.show();
      enableFields(creditSection);
      insuranceSection.hide();
      disableFields(insuranceSection);
    }
  };

  // Llamar a toggleSections al cambiar el tipo de financiamiento
  $('#financier_financing_type').on('change', function () {
    const selectedType = $(this).val();
    toggleSections(selectedType);
  });

  // Ejecutar la lógica al cargar la página para manejar la sección visible/invisible según el valor inicial
  const initialSelectedType = $('#financier_financing_type').val();
  toggleSections(initialSelectedType);

}

const setCountryToBilling = (countryID = null) => {
  const billingAddressCountry = $("#billing_address_country_id")
  billingAddressCountry.val(countryID);
}

const startToggleCountries = () => {

  if ($('.admin-financier-form').length) {

    // Ejecutar al cargar la página
    toggleCountryFields();
    startToggleSections();

    // Escuchar el cambio en la selección del país
    $('#financier_country_select').on('change', function () {
      toggleCountryFields();
    });


    // Validación de los campos de dinero
    const moneyFields = [
      '#financier_min_value_cents',
      '#financier_max_value_cents',
      '#financier_fixed_monthly_charge_cents',
      '#financier_variable_monthly_charge_cents',
      '#financier_evaluation_limit_cents'
    ];

    moneyFields.forEach(function (selector) {
      $(selector).on('input', function () {
        let value = $(this).val();

        // Permitir solo números y puntos
        value = value.replace(/[^0-9.]/g, '');

        // Limitar a un solo punto decimal
        const parts = value.split('.');
        if (parts.length > 2) {
          value = parts[0] + '.' + parts.slice(1).join('');
        }

        // Limitar a 999.999.999
        const numericValue = parseFloat(value);
        if (numericValue > 999999999) {
          value = '999999999';
        }

        // Asegurar que sea positivo
        if (numericValue < 0) {
          value = Math.abs(numericValue).toString();
        }

        $(this).val(value);
      });
    });

    // Función para obtener el símbolo de la moneda
    const getCurrencySymbol = (currency) => {
      switch (currency) {
        case 'CLF':
          return 'UF';
        case 'CLP':

        case 'USD':

        default:
          return currency || '-';
      }
    };

    // Escuchar cambios en el selector de moneda
    $('.currency-selector').on('change', function () {
      const selectedCurrency = $(this).val();
      const currencySymbol = getCurrencySymbol(selectedCurrency);

      // Actualizar todos los elementos con el target 'currency-symbol'
      $('[data-currency-target="currency-symbol"]').text(currencySymbol);
    });

    // Ejecutar la lógica al cargar la página para establecer el símbolo correcto
    const initialCurrency = $('.currency-selector').val();
    const initialSymbol = getCurrencySymbol(initialCurrency);
    $('[data-currency-target="currency-symbol"]').text(initialSymbol);

  }
}

const startExclusiveProviders = () => {
  if ($('.admin-financier-form').length) {
    // Inicialización de select2
    $('.js-multiple-exclusive-providers').select2({
      width: '100%',
    });

    // Crear el contenedor para el mensaje de error
    const errorMessage = $('<div class="text-danger mt-2">Debes seleccionar al menos 1 prestador exclusivo para ser un financiador exclusivo</div>');
    $('.credit-exclusive-providers-select').after(errorMessage);
    errorMessage.hide(); // Ocultar el mensaje al inicio

    const validateExclusiveProviders = () => {
      let isChecked = $('#credit_exclusive').is(':checked');
      let selectedProviders = $('.js-multiple-exclusive-providers').val();

      if (isChecked && (!selectedProviders || selectedProviders.length === 0)) {
        errorMessage.show(); // Mostrar el mensaje de error
      } else {
        errorMessage.hide(); // Ocultar el mensaje de error
      }
    };

    // Manejar la visualización inicial del selector de prestadores exclusivos
    if ($('#credit_exclusive').is(':checked')) {
      $('.credit-exclusive-providers-select').show();
      $('.js-multiple-exclusive-providers').prop('disabled', false);
    } else {
      $('.credit-exclusive-providers-select').hide();
      $('.js-multiple-exclusive-providers').prop('disabled', true);
    }

    // Escuchar cambios en el switch de credit_exclusive
    $('#credit_exclusive').change(function () {
      if ($(this).is(':checked')) {
        $('.credit-exclusive-providers-select').show();
        $('.js-multiple-exclusive-providers').prop('disabled', false);
      } else {
        $('.credit-exclusive-providers-select').hide();
        $('.js-multiple-exclusive-providers').prop('disabled', true);
      }
      validateExclusiveProviders(); // Validar al cambiar el switch
    });

    // Escuchar cambios en el select de prestadores exclusivos
    $('.js-multiple-exclusive-providers').on('change', function () {
      validateExclusiveProviders(); // Validar al cambiar la selección
    });

    // Validar al cargar la página
    validateExclusiveProviders();
  }
};


const activateButton = () => {
  const submitButton = $(".admin-financier-form").find('input[type="submit"]');

  if (submitButton) {
    submitButton.prop('disabled', false);
  }

}

const startValidateForm = () => {

  if ($(".admin-financier-form").length <= 0) {
    return
  }

  if (window.validator) {
    window.validator = validator
  } else {
    window.validator = new JustValidate('.admin-financier-form', globalConfigFormValidator);
  }

  $("#billingModal").on("show.bs.modal", () => {
    activateButton()
  })

  $("#billingModal").on("hidden.bs.modal", () => {
    $(".alert-form-errors").hide()
    window.validator.refresh()
    activateButton()
  })

  Object.entries(defaultFields).forEach(([key, values]) => {
    if ($(key).length) {
      validator.addField(key, values);
    }
  });

  window.validator.onFail(() => {
    $(".alert-form-errors").show()
    return $("#billingModal").modal('show');
  })

  window.validator.onSuccess(() => {
    $(".alert-form-errors").hide()
    if (document.querySelector(".rut-auto-format")?.classList.contains("is-invalid")) {
      $(".alert-form-errors").show()
      return $("#billingModal").modal('show');
    }

    $(".admin-financier-form").submit();

  })

}


const removeAllFields = () => {
  // Eliminamos por si es que no hay fields del país, y si es que ya se agregaron
  for (const key in window.validator.fields) {
    const elem = window.validator.fields[key];
    if ({ ...allFields, ...defaultFields }.hasOwnProperty(`#${elem.elem.id}`)) {
      window.validator.removeField(`#${elem.elem.id}`);
    }
  }
}


const countryFields = {
  '142': mexicanFields,
  '50': colombianFields,
  '1': chileanFields
}

const addOrRemoveFieldsFormValidator = (countryID, create = false) => {
  if (!window.validator) {
    return
  }
  const billingTarget = $("#financier_billing_target")
  // Es facturación paciente? Eliminamos validaciones
  if (billingTarget.val() === "patient_invoice") {
    return removeAllFields()
  }

  // Eliminamos por si es que no hay fields del país, y si es que ya se agregaron
  for (const key in window.validator.fields) {
    const elem = window.validator.fields[key];
    if (allFields.hasOwnProperty(`#${elem.elem.id}`)) {
      window.validator.removeField(`#${elem.elem.id}`);
    }
  }

  if (create) {
    // Agregamos las otras si es que se crea de nuevo el form
    Object.entries(defaultFields).forEach(([key, values]) => {
      if ($(key).length) {
        window.validator.addField(key, values)
      }
    });
  }

  // Seleccionamos país y agregamos, luego eliminamos si es que
  if (countryFields[countryID.toString()]) {
    Object.entries(countryFields[countryID.toString()]).forEach(([key, values]) => {
      if ($(key).length) {
        window.validator.addField(key, values)
      }
    });
  }

  window.validator.refresh()
}

const startBillingTargetValidations = () => {
  const billingTarget = $("#financier_billing_target")
  billingTarget.on("select2:select", (e) => {
    addOrRemoveBillingValidations(e.target.value)
  })

  addOrRemoveBillingValidations(billingTarget.val())
}

const addOrRemoveBillingValidations = (billingTarget) => {
  if (window.validator) {
    const selectedCountryId = $('#financier_country_select').val();
    if (billingTarget === "insurer_invoice" || billingTarget === "patient_invoice") {
      // Facturación requerida
      // Agregamos el billingAddress
      setCountryToBilling(selectedCountryId)
      addOrRemoveFieldsFormValidator(selectedCountryId, true)
      return
    } else {
      // AQUI NO VALIDAR RUT
      removeAllFields()
      // No requiere validación
      window.validator.destroy()
    }

  }
}

const toggleCreditConfiguration = (value = false) => {
  if (value) {
    return $("#credit-configuration").show();
  }

  // Set values to false
  $("#financier_requires_validation").prop("checked", false);
  $("#credit_exclusive").prop("checked", false);
  $("#financier_bnpl_enabled").prop("checked", false);
  $("#financier_qr_enabled").prop("checked", false);
  $('.js-multiple-exclusive-providers').val(null).trigger('change');
  $('.credit-exclusive-providers-select').hide()

  $("#credit-configuration").hide();
}

const startToggleCreditSection = () => {
  const financierCreditEnabledToggle = $("#financier_credit_enabled")
  const value = financierCreditEnabledToggle.is(':checked')

  financierCreditEnabledToggle.on('change', function (e) {
    toggleCreditConfiguration(e.target.checked)
  })

  if ($(".financier-credit-section").length > 0) {
    toggleCreditConfiguration(value)
  }
}

$(document).on('turbolinks:load', function () {

  if ($(".alert-form-errors").length > 0) {
    $(".alert-form-errors").hide()
  }

  if ($('.admin-financier-form').length) {
    initializeOriginalCountry()
    startAutoSendToggles()
    startValidateForm()
    startToggleCountries()
    startExclusiveProviders()

    startBillingTargetValidations()
    startToggleCreditSection()
  }
});