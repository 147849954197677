/*
Este js se encarga del siguiente comportamiento:
  Si hay un input con la clase .rut-auto-format, cada vez que el foco pase por este campo
  se realizará un formateo del valor para que tenga un formato de rut válido.
  Así también, se realizará una validación de dicho valor para determinar si es un no un rut válido.
  Si el rut es inválido se creará un span debajo del input con el mensaje de error y se previene el submit.
 */

const RUT_FORMAT_COMPONENT_SELECTOR = '.rut-auto-format';
const COUNTRY_FORMAT_COMPONENT_SELECTOR = '.country-auto-format';

$(document).on('turbolinks:load', function () {
  $('.form, .modal, .modal-body').each(function () {
    let $container = $(this);
    let $country = $container.find(COUNTRY_FORMAT_COMPONENT_SELECTOR);
    let $ruts = $container.find(RUT_FORMAT_COMPONENT_SELECTOR);

    if ($ruts.length > 0 && $country.length > 0) {
      $ruts.each(function () {
        new RutFormatter($(this), $country).listen();
        new RutValidator($(this), $country).listen();
      });
    }
  });
});

function RutFormatter($input, $country) {
  let rthis = this;
  this.$input = $input;
  this.$country = $country;

  this.itIsNotAChileanCountry = function () {
    if (rthis.$country.val() === "1" || rthis.$country.val() === "rut")
      return false;
    return true;
  };

  this.listen = function () {
    this.$input.on('blur', function () {
      if (rthis.itIsNotAChileanCountry()) return;
      rthis.updateFormat();
    });
    this.$input.closest('form').on('submit', function () {
      if (rthis.itIsNotAChileanCountry()) return;
      rthis.updateFormat();
    });
  };

  this.updateFormat = function () {
    if (rthis.itIsNotAChileanCountry()) return this.$input.val(this.$input.val());
    this.$input.val(this.format(this.$input.val()));
  };

  this.clean = function (rutString) {
    if (rthis.itIsNotAChileanCountry()) return rutString;
    if (!rutString) return rutString;
    return rutString.replace(/[^\dkK]/g, "").toUpperCase();
  };

  this.format = function (rutString) {
    if (rthis.itIsNotAChileanCountry()) return rutString;
    let cleanRut, count, formattedRut, i, _i, _ref;
    cleanRut = this.clean(rutString);
    if (!cleanRut || cleanRut.length === 0) return cleanRut;
    formattedRut = "-" + cleanRut.charAt(cleanRut.length - 1);
    count = 0;
    for (i = _i = _ref = cleanRut.length - 2; _ref <= 0 ? _i <= 0 : _i >= 0; i = _ref <= 0 ? ++_i : --_i) {
      formattedRut = cleanRut.charAt(i) + formattedRut;
      count++;
      if (count === 3) {
        count = 0;
        if (i > 0) formattedRut = "." + formattedRut;
      }
    }
    return formattedRut;
  };

  rthis.itIsNotAChileanCountry();
}

function RutValidator($input, $country) {
  let rthis = this;
  this.$input = $input;
  this.$country = $country;

  this.listen = function () {
    this.$input.on('blur', function () {
      if (rthis.itIsNotAChileanCountry()) return;
      if (rthis.validate()) {
        rthis.activateSubmit();
        rthis.removeError();
      } else {
        rthis.addError();
        rthis.disableSubmit();
      }
    });
    this.$input.closest('form').on('submit', function (ev) {
      if (rthis.itIsNotAChileanCountry()) return;
      if (!rthis.validate()) {
        ev.preventDefault();
        rthis.addError();
        rthis.disableSubmit();
      } else {
        rthis.activateSubmit();
        rthis.removeError();
      }
    });
    this.$country.on('change', function () {
      if (rthis.itIsNotAChileanCountry()) {
        rthis.removeError();
        rthis.activateSubmit();
      } else {
        // Si se cambia a RUT, hacer validaciones de inmediato
        if (!rthis.validate()) {
          rthis.addError();
          rthis.disableSubmit();
        } else {
          rthis.removeError();
          rthis.activateSubmit();
        }
      }
    });
  };

  this.itIsNotAChileanCountry = function () {
    if (rthis.$country.val() === "1" || rthis.$country.val() === "rut")
      return false;
    rthis.removeError();
    return true;
  };

  this.validate = function () {
    if (rthis.itIsNotAChileanCountry()) return true;
    let rawRutString = this.$input.val();
    if (!rawRutString) return true;
    let rutString = this.format(rawRutString);
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutString)) return false;
    let tmp = rutString.split('-');
    let digv = tmp[1];
    let rut = tmp[0];
    if (digv == 'K') digv = 'k';
    return (this.dv(rut) == digv);
  };

  this.format = function (rut) {
    if (rthis.itIsNotAChileanCountry()) return rut;
    if (!rut) return rut;
    return rut.replace(/[^0-9kK\-]/g, '');
  };

  this.dv = function (rut) {
    if (rthis.itIsNotAChileanCountry()) return rut;
    let M = 0, S = 1;
    for (; rut; rut = Math.floor(rut / 10)) {
      S = (S + rut % 10 * (9 - M++ % 6)) % 11;
    }
    return S ? S - 1 : 'k';
  };

  this.addError = function () {
    if (rthis.itIsNotAChileanCountry()) return;
    this.$input.addClass("is-invalid");
    if (this.$input.next("span").length <= 0) {
      this.$input.after("<span class='text-danger'>Rut inválido</span>");
    }
  };

  this.removeError = function () {
    this.$input.removeClass("is-invalid");
    this.$input.next("span").remove();
  };

  this.disableSubmit = function () {
    if (rthis.itIsNotAChileanCountry()) return;
    this.$input.focus();
    this.$input.closest("form").find("input[type='submit']").attr("disabled", true);
  };

  this.activateSubmit = function () {
    this.$input.closest("form").find("input[type='submit']").attr("disabled", false);
  };

  rthis.itIsNotAChileanCountry();
}