import $ from "jquery";

$(document).on("turbolinks:load", () => {
  if ($("#user_form").length) {
    const toggleFields = () => {
      const roleValue = $("#user_role").val();
      const providerSelect = $("#user_provider_id");
      const wsTypeSelect = $("#user_ws_type");

      if (roleValue === "provider") {
        // Mostrar y habilitar el campo de prestador
        $("#provider_fields").show();
        providerSelect.prop("disabled", false);
        $("#user_phone_country_code").prop("required", true).prop("disabled", false);
        $("#user_phone").prop("required", true).prop("disabled", false);
      } else {
        // Ocultar y deshabilitar el campo de prestador
        providerSelect.prop("disabled", true);
        $("#provider_fields").hide();
        $("#user_phone_country_code").prop("required", false).prop("disabled", true);
        $("#user_phone").prop("required", false).prop("disabled", true);
      }

      if (roleValue === "map") {
        $("#api_owner_field").show();
      } else {
        $("#api_owner_field").hide();
        $("#user_api_owner_id").val('');
      }

      if (roleValue === "api_consumer") {
        $("#ws_type_field").show();
        wsTypeSelect.prop("disabled", false);
      } else {
        // Ocultar y deshabilitar el campo de ws_type, y establecer "No utiliza API"
        $("#ws_type_field").hide();
        wsTypeSelect.val('none'); // Se asegura que el valor sea "No utiliza API"
      }
    };

    // Ejecutar la función al cargar la página y al cambiar el rol
    toggleFields();
    $("#user_role").on("change", () => {
      toggleFields();
    });

    $("#user_ws_type").on("change", () => {
      const roleValue = $("#user_role").val();

      if (roleValue === "api_consumer" && $("#user_ws_type").val() === "maps") {
        $("#financier_map_field").show();
      } else {
        $("#financier_map_field").hide();
        $("#user_financier_map_id").val('none');
      }
    });

    const ssoOnlySelect = $("#sso-only-select");

    // Usuarios solo sso. Esta funcion actualiza los campos de contraseña
    // según la opción seleccionada de solo sso
    const updateSSOOnly = function() {
      let currentValue = ssoOnlySelect.val()
      if (currentValue == 'force') {
        // ocultamos la pass
        $('.pass-fields').addClass('d-none')
        // esta contraseña en realidad no se guardará
        let randomString = "Random Fake Pass 1.0 - " + Math.random().toString(36)
        $('.pass-fields input[type=password]').val(randomString).trigger('keyup')
      } else {
        $('.pass-fields').removeClass('d-none')
        $('.pass-fields input[type=password]').val('').trigger('keyup')
      }
    };
    // lo triggereamos al cambiar y al inicio
    ssoOnlySelect.on('change', updateSSOOnly)
    updateSSOOnly()

    // obtengamos los regexp que identifican los correos restringidos:
    const regexps = ssoOnlySelect.data('restrictedEmails').map((regex) => new RegExp(regex))
    const forceRestricted = ssoOnlySelect.data('force') == 'force'

    // funcion que sugiere el tipo de SSO segun el correo
    // considerando los correos restringidos a solo sso
    const updateRestricted = function() {
      let email = $('#user_email').val()
      let emailIsRestricted = regexps.some((regexp) => regexp.test(email))

      if (emailIsRestricted) {
        // el sso es SUGERIDO u obligatorio, vamos a ponerlo por defecto
        ssoOnlySelect.val('force');
        if (forceRestricted) {
          // si hay que forzarlo, quitamos la opción de ingreso con contraseña
          ssoOnlySelect.find('option:first').prop('disabled', true);
        }
        ssoOnlySelect.trigger('change');
      } else {
        ssoOnlySelect.find('option:first').prop('disabled', false);
        ssoOnlySelect.trigger('change');
      }
    };

    // lo triggereamos cuando cambia el correo y al inicio
    $('#user_email').on('change', updateRestricted);
    updateRestricted();

    // Validación de contraseñas para habilitar/deshabilitar el botón de submit
    $('#pass, #confirmPass').on('keyup', () => {
      $('input[name="commit"]').prop('disabled', true);
      const pass = $('#pass').val();
      const confirmPass = $('#confirmPass').val();
      if (pass === confirmPass) {
        $('input[name="commit"]').prop('disabled', false);
        $('#passErrorMessage').text('');
      } else {
        $('input[name="commit"]').prop('disabled', true);
        $('#passErrorMessage').text('Error: contraseñas son distintas');
      }
    });

    // Agregar el filtro para prevenir la inserción de emojis
    const fields = document.querySelectorAll('input[type="text"], textarea, input[type="password"], input[type="email"]');

    fields.forEach(function(field) {
      field.addEventListener('beforeinput', function(event) {
        // Verifica si el valor ingresado es un emoji
        const emojiRegex = /[\p{Emoji_Presentation}\u200d\u20e3\ufe0f]/gu;
        if (emojiRegex.test(event.data)) {
          event.preventDefault(); // Previene la inserción del emoji
        }
      });
    });
  }
});
