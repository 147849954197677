// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require('jquery')

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

require('bootstrap');
import 'cocoon-js'
import 'flatpickr';
require("flatpickr/dist/themes/material_blue.css");

require('./utilities/select2')

require('react')
require('react-dom')
require('prop-types')

//pages
require('./pages/provider_maps')
require('./pages/branches')
require('./pages/billings')
require('./pages/account_movement')
require('./pages/account_bond')
require('./pages/home_contract')
require('./pages/financiers')

require('./pages/admin/payment_requests')
require('./pages/admin/admin_search')
require('./pages/admin/payment_urls')
require('./pages/admin/reveniu_subscriptions')
require('./pages/admin/providers')
require('./pages/admin/forms_with_select2')
require('./pages/admin/financiers')
require('./pages/admin/users_form')
require('./pages/admin/dm_providers')
require('./pages/admin/edit_branch_billing_section')
require('./pages/admin/filters')
require('./pages/admin/translations')
require('./pages/payment_requests/movements_grid')
require('./pages/admin/country_configs')

require('./utilities/form_with_required')
require('./utilities/showable_password')
require('./utilities/resend_activation_email')
require('./utilities/rut_auto_format')
require('./utilities/phone_format')
require('./utilities/copy_clipboard')
require('./utilities/inline_logo_field')
require('./utilities/bootstrap_shortcuts')
require('./pages/wizard_connect')
require('./pages/wizard_user_data')
require('./pages/wizard_provider_form')
require('./pages/wizard_confirm_payment')
require('./pages/wizard_waiting_translations')
require('./pages/credit_financings_show')
require('./credits_payment_app/index')
require('./credits/errors/index')
require('./credit_financings/unique_credit')
require('./administrate-field-money/jquery.maskMoney')
require('./administrate-field-money/application')
require('./credits_success_app/index')
require('./bnpl_lending_success/index')
require('./app_status/jwt_checker.js')
require('./caja_los_andes_lending/amount_by_installments')
require('./caja_los_andes_lending/success')
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('trix')
require('@rails/actiontext')
